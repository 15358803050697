import * as React from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import LoadingProgress from './components/Commons/LoadingProgress';
import useSessionPlQuery from './hooks/queries/useSessionPlQuery';
import useLoginSeenCookie from './hooks/useLoginSeenCookie';
import useSession from './hooks/useSession/useSession';
import { useStaffQuery } from './hooks/queries/useStaffQuery/useStaffQuery';

export default function RoutingHandler() {
  const { session, removeCookie } = useSession();
  const sessionQuery = useSessionPlQuery();

  const [searchParams] = useSearchParams();
  const { loginSeen } = useLoginSeenCookie();

  const referer = searchParams.get('referer');
  if (sessionQuery.isPending) {
    return <LoadingProgress loading />;
  }

  if (!session) {
    let to = '/login';
    if (referer) {
      to = `${to}?referer=${referer}`;
    }
    return <Navigate to={to} replace />;
  }
  if (!sessionQuery.data) {
    removeCookie('SESSION_STARTED');
    let to = '/no-session';
    if (referer) {
      to = `${to}?referer=${referer}`;
    }
    return <Navigate to={to} replace />;
  }

  const { type, id } = sessionQuery.data.login.default_account;
  const isOldRoute = window.location.href.includes('/#/');

  if (isOldRoute) {
    const oldPath = window.location.href.split('/#/')?.[1];
    return <Navigate to={oldPath} replace />;
  }

  if (referer) {
    const refererURL = new URL(decodeURIComponent(referer ?? ''), window.location.origin);
    return (
      <Navigate
        to={{
          pathname: refererURL.pathname,
          search: refererURL.search,
        }}
        replace
      />
    );
  }
  if (type && id && type !== 'none') {
    <Navigate to={`/${type}/${id}`} replace />;
  }
  if (sessionQuery.data.login.is_dianomi && !loginSeen) {
    // eslint-disable-next-line no-use-before-define
    return <StaffNavigate />;
  }
  if (!loginSeen) {
    return <Navigate to="/select-account" replace />;
  }
  return (
    <Navigate
      to={`/${sessionQuery.data?.isPartner ? 'partner' : 'client'}/${sessionQuery.data
        ?.id}/dashboard`}
      replace
    />
  );
}

function StaffNavigate() {
  const query = useStaffQuery();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (query.isError) {
      setTimeout(() => {
        navigate('/select-account', { replace: true });
      }, 5000);
    }
  });
  if (query.isPending) {
    return null;
  }
  if (query.isError) {
    return (
      <h2>
        It looks like there&apos;s been an issue getting your staff data. let the MyDianomi team
        know. you will be redirected to the non-staff area momentarily
      </h2>
    );
  }
  // as we don't have anything useful for publisher account managers yet,
  // redirect straight to main dashboard
  if (
    query.data?.permissions.partner &&
    !query.data?.permissions.admin &&
    !query.data.permissions.client
  ) {
    return <Navigate to="/select-account" replace />;
  }

  return <Navigate to="/staff/select-account" replace />;
}
