import React, { useCallback, useMemo, useReducer } from 'react';

export type NotificationLevels = 'info' | 'warning' | 'success' | 'danger' | 'primary';
type NotificationMessage = string | React.ReactNode;

export type SetNotification = (
  open: boolean,
  notificationLevel: NotificationLevels,
  notificationMessage: NotificationMessage,
) => void;

type CloseNotification = () => void;

type SetTimedNotification = (
  notificationLevel: NotificationLevels,
  notificationMessage: NotificationMessage,
  timeout: number,
) => void;

type Notification = {
  showNotification: boolean;
  notificationMessage: NotificationMessage;
  notificationLevel: NotificationLevels;
  setNotification: SetNotification;
  closeNotification: CloseNotification;
  setTimedNotification: SetTimedNotification;
};

// avoid importing this if possible, it's only exported for legacy reasons - checked: legacy 10/1/24
export const Notifications = React.createContext<Notification | null>(
  null,
) as React.Context<Notification>;

const defaultState = {
  showNotification: false,
  notificationMessage: 'danger',
  notificationLevel: 'warning',
} as const;

type NotificationState = {
  showNotification: boolean;
  notificationMessage: NotificationMessage;
  notificationLevel: NotificationLevels;
};

// this is the provider component for the notifications context,
// currently wrapped around the App component and also needs to be wrapped around
// test components that use the context
type Reducer<S, A> = (oldState: S, newState: A) => S;

export function NotificationsProvider({ children }: { children: React.ReactNode }) {
  const [notifications, notificationsDispatch] = useReducer<
    Reducer<NotificationState, Partial<NotificationState>>
  >(
    (oldState, newState) => ({
      ...oldState,
      ...newState,
    }),
    defaultState,
  );
  // possible levels info, warning, success, danger, primary

  const setNotification: SetNotification = useCallback(
    (open, notificationLevel, notificationMessage) => {
      notificationsDispatch({
        showNotification: open,
        notificationLevel,
        notificationMessage,
      });
    },
    [],
  );
  const closeNotification = useCallback(() => {
    notificationsDispatch({
      showNotification: false,
    });
  }, []);

  const setTimedNotification: SetTimedNotification = useCallback(
    (notificationLevel, notificationMessage = '', time = 5000) => {
      setNotification(true, notificationLevel, notificationMessage);
      setTimeout(() => {
        closeNotification();
      }, time);
    },
    [closeNotification, setNotification],
  );
  const value = useMemo(
    () =>
      ({
        ...notifications,
        setNotification,
        closeNotification,
        setTimedNotification,
      }) as const,
    [notifications, setNotification, closeNotification, setTimedNotification],
  );
  return <Notifications.Provider value={value}>{children}</Notifications.Provider>;
}

// this is the consumer component for the notifications context,
const useNotifications = () => {
  const context = React.useContext(Notifications);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationsProvider');
  }
  return context;
};

export default useNotifications;

// this is a consumer HOC for legacy class components, only use this where you need to
// access the notifications context within class components. see for example in admin.jsx - legacy: checked 10/1/24
export const withNotifications =
  <P extends Notification>(Component: React.ComponentType<P>): React.FC<P> =>
  (props) => {
    const notifications = useNotifications();
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component notifications={notifications} {...props} />;
  };
