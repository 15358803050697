import * as Sentry from '@sentry/react';
import { customFetch } from '~/utils/customFetch';

/**
 * @param {Object} [params]
 * @param {string} [params.id]
 * @param {string | number} [params.clientId]
 * @param {number} [params.productId]
 * @returns {Promise<unknown>}
 */
function getCampaigns({ id = undefined, clientId, productId } = {}) {
  const requestOptions = { params: {}, useNodeEndpoint: true };

  if (id) {
    requestOptions.params.id = id;
  }
  if (clientId) {
    requestOptions.params.client_id = clientId;
  }
  if (productId) {
    requestOptions.params.productId = productId;
  }
  return customFetch
    .get(`/authenticated/client/${clientId}/ad-manager/products`, requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET service_adgroup_plus_2.pl',
            file: 'CapsService.jsx',
            fn: 'getCampaigns()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET service_adgroup_plus_2.pl',
          file: 'CapsService.jsx',
          fn: 'getCampaigns()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

function getCapPublishers(campaign, from, to, clicks, id) {
  const requestOptions = {
    params: {
      from,
      to,
      min_clicks: clicks,
      client_id: id,
      product_id: campaign[0] === 'all' ? '' : campaign[0],
      show_block: 1,
    },
  };
  return customFetch.get(`/client_bid.pl`, requestOptions).then((result) => result.data);
}

/**
 * Retrieves device bidding statistics for a given campaign and timeframe.
 * @param {string?} campaign - The ID of the campaign to retrieve device bidding statistics for.
 * @param {string } from - Date from.
 * @param {string } to - Date to.
 * @returns {Promise<{
 *   computer: {
 *     actions: string,
 *     clicks: string,
 *     ctr: number,
 *     impressions: string,
 *     adjustment?: number,
 *      client_adjustment?: number,
 *     conversion_rate?: number
 *     cpa?: number
 *     cost: number,
 *   },
 *   mobile: {
 *     actions: string,
 *     clicks: string,
 *     ctr: number,
 *     impressions: string,
 *     cost: number,
 *     conversion_rate?: number
 *     cpa?: number
 *      client_adjustment?: number,
 *     adjustment?: number,
 *   },
 *   tablet: {
 *     actions: string,
 *     clicks: string,
 *     ctr: number,
 *     impressions: string,
 *     cost: number,
 *     conversion_rate?: number
 *     cpa?: number
 *     client_adjustment?: number,
 *     adjustment?: number,
 *   },
 *    unknown?: {
 *      actions: string,
 *      clicks: string,
 *      ctr: number,
 *      impressions: string,
 *      cost: number,
 *      adjustment?: number,
 *      client_adjustment?: number,
 *     conversion_rate?: number
 *     cpa?: number
 * },
 *   price?: number,
 * } | {error: string}>} - A promise that resolves with an object containing device bidding statistics.
 */
function getDeviceBiddingStats(campaign, from, to) {
  const requestOptions = {
    params: {
      from,
      to,
      device: 1,
    },
  };
  if (campaign) {
    requestOptions.params.product_id = campaign;
  }

  return customFetch
    .get(`/client_bid.pl`, requestOptions)
    .then(
      ({ data }) => {
        if (data.error) {
          return data;
        }
        const devices = ['computer', 'mobile', 'tablet'];

        let newData = {};

        // create default data for each device so there is always data for each device
        devices.forEach((device) => {
          newData[device] = {
            actions: '0',
            clicks: '0',
            ctr: 0,
            impressions: '0',
            cost: 0.0,
          };
        });
        // overwrite default data with data from API
        newData = { ...newData, ...data };

        return newData;
      },
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET client_bid.pl',
            file: 'CapsService.jsx',
            fn: 'getDeviceBiddingStats()-01',
          });
          Sentry.captureException(error);
        });
        return { error: 'unknown error' };
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET client_bid.pl',
          file: 'CapsService.jsx',
          fn: 'getDeviceBiddingStats()-02',
        });
        Sentry.captureException(error);
      });
      return { error: 'unknown error' };
    });
}

const blockPublisher = async (productId, publisherId, block) => {
  const data = {
    publisher_id: publisherId,
    block,
  };
  if (productId) {
    data.product_id = productId;
  }

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const result = await customFetch.post(`/client_bid.pl`, JSON.stringify(data), requestOptions);
  return result.data;
};

function getFilterCampaigns(client, isPartner, from, to) {
  const requestOptions = {
    params: {
      from,
      to,
      visualization: 'product_list',
      linkID: 'undefined',
    },
    shouldUseBasePath: true,
  };
  if (isPartner) {
    requestOptions.params.partner_id = client;
  } else {
    requestOptions.params.client_id = client;
  }
  return customFetch
    .get(`/geneus.pl`, requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET geneus.pl',
            file: 'CapsService.jsx',
            fn: 'getFilterCampaigns()-01',
          });
          Sentry.captureException(error);
        });
        return [];
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET geneus.pl',
          file: 'CapsService.jsx',
          fn: 'getFilterCampaigns()-01',
        });
        Sentry.captureException(error);
      });
      return [];
    });
}

function getRegions(query = null) {
  const requestOptions = query
    ? {
        params: {
          q: query,
        },
      }
    : {};

  return customFetch
    .get(`/regions2JSON.pl`, requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET regions2JSON.pl',
            file: 'CapsService.jsx',
            fn: 'getRegions()-01',
          });
          Sentry.captureException(error);
        });
        return [];
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET regions2JSON.pl',
          file: 'CapsService.jsx',
          fn: 'getRegions()-02',
        });
        Sentry.captureException(error);
      });
      return [];
    });
}

function getProducts(query) {
  const requestOptions = query
    ? {
        params: {
          q: query,
        },
      }
    : {};
  return customFetch
    .get(`/productTypesJSON.pl`, requestOptions)
    .then(
      (result) => {
        if (!result.data) {
          return [];
        }
        const products = [];
        // Process response to a nested object
        Object.values(result.data).forEach((product) => {
          if (product.name) {
            if (!Object.prototype.hasOwnProperty.call(products, product.vertical_name)) {
              products[product.vertical_name] = {
                name: product.vertical_name,
                id: product.vertical_name,
                children: [],
              };
            }
            products[product.vertical_name].children.push({
              name: product.name,
              id: product.id,
              category: product.vertical_name,
            });
          }
        });
        // Rebuild object removing indexes
        return Object.keys(products).map((value) => products[value]);
      },
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET productTypesJSON.pl',
            file: 'CapsService.jsx',
            fn: 'getProducts()-01',
          });
          Sentry.captureException(error);
        });
        return [];
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET productTypesJSON.pl',
          file: 'CapsService.jsx',
          fn: 'getProducts()-02',
        });
        Sentry.captureException(error);
      });
      return [];
    });
}

function getValidJSTrackers() {
  return customFetch
    .get(`/js_approved_domains.pl`)
    .then(
      (result) => {
        if (result.data) {
          return result.data;
        }
        return [];
      },
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET js_approved_domains.pl',
            file: 'CapsService.jsx',
            fn: 'getValidJSTrackers()-01',
          });
          Sentry.captureException(error);
        });
        return [];
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET js_approved_domains.pl',
          file: 'CapsService.jsx',
          fn: 'getValidJSTrackers()-02',
        });
        Sentry.captureException(error);
      });
      return [];
    });
}

/**
 *
 * @param {number | string | undefined} idCampaign
 * @param {'hide_schedule' | 'partners' | 'audience' | 'geo'} category
 * @param {any} dataTargeting
 * @param {number | string | undefined} clientId
 * @param {number | string | undefined} productId
 * @returns {Promise<{error: string} | {}>}
 */
function updateTargeting(idCampaign, category, dataTargeting, clientId, productId) {
  const data = {
    id: idCampaign,
    targeting: {
      [category]: dataTargeting,
    },
    client_id: clientId,
    product_id: productId,
  };
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return customFetch
    .post(`/change_targeting.pl`, JSON.stringify(data), requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to POST change_targeting.pl',
            file: 'CapsService.jsx',
            fn: 'updateTargeting()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to POST change_targeting.pl',
          file: 'CapsService.jsx',
          fn: 'updateTargeting()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

function saveCampaign(campaignData) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return customFetch
    .post(`/service_adgroup_plus_2.pl`, JSON.stringify(campaignData), requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to POST service_adgroup_plus_2.pl',
            file: 'CapsService.jsx',
            fn: 'saveCampaign()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to POST service_adgroup_plus_2.pl',
          file: 'CapsService.jsx',
          fn: 'saveCampaign()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}
/**
 *
 * @param {{productId?: number | string | undefined, adgroupId?: number | string | undefined, action: string}} Props
 * @returns {Promise<{status: string} | {error: string}>}
 */
function updateCapStatus({ productId, adgroupId, action }) {
  const data = {
    action,
  };
  if (productId) {
    data.product_id = productId;
  }
  if (adgroupId) {
    data.id = adgroupId;
  }
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return customFetch
    .post(`/change_status.pl`, JSON.stringify(data), requestOptions)
    .then((result) => result.data)
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to POST change_status.pl',
          file: 'CapsService.jsx',
          fn: 'updateCapStatus()-02',
        });
        Sentry.captureException(error);
      });
      return { error };
    });
}

function updateVariantStatus(variantId, action) {
  const data = {
    action,
    variant_id: variantId,
  };
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return customFetch.post(`/change_variant_status.pl`, JSON.stringify(data), requestOptions);
}

function updatePublisherStatus(idPublisher, action) {
  // @TODO Integrate with API
  const dummyResponse = {
    status: action === 'activate' ? 1 : 0,
  };
  const data = {
    action,
    id: idPublisher,
  };
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return customFetch
    .post(`/publisher_change_status.pl`, JSON.stringify(data), requestOptions)
    .then(
      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      (result) => dummyResponse,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to POST publisher_change_status.pl',
            file: 'CapsService.jsx',
            fn: 'updatePublisherStatus()-01',
          });
          Sentry.captureException(error);
        });
        return dummyResponse;
        // return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to POST publisher_change_status.pl',
          file: 'CapsService.jsx',
          fn: 'updatePublisherStatus()-02',
        });
        Sentry.captureException(error);
      });
      return dummyResponse;
      // return null;
    });
}
/**
 * @typedef {'NONE' | 'ALL' | 'ACTIVE'} CopyOptions
 * @typedef {{adgroup: CopyOptions, variant: CopyOptions}} CopyOptionsObject
 *
 * @param {number} productId
 * @param {CopyOptionsObject} copyOptions
 * @returns {Promise<{id: string} | {error: string}>}
 */
function cloneCampaign(productId, copyOptions) {
  return customFetch
    .post(
      '/service_adgroup_plus_copy.pl',
      JSON.stringify({
        product_id: productId,
        adgroup_opts: copyOptions.adgroup,
        variant_opts: copyOptions.variant,
      }),
    )
    .then(
      ({ data }) => data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: `Error trying to POST service_adgroup_plus_copy.pl`,
            file: 'CapsService.jsx',
            fn: 'cloneCampaign()-01',
          });
          Sentry.captureException(error);
        });
        return { error: 'Error trying to copy campaign' };
      },
    );
}

/**
 *
 * @param {number? | undefined} productId
 * @param {number} price
 * @returns {Promise<{status: string} | {error: string}>}
 */
function updatePrice(productId, price) {
  const data = {
    price,
    product_id: productId,
  };

  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return customFetch
    .post(`/change_price.pl`, JSON.stringify(data), requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to POST change_price.pl',
            file: 'CapsService.jsx',
            fn: 'updatePrice()-01',
          });
          Sentry.captureException(error);
        });
        return { error: error.message };
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to POST change_price.pl',
          file: 'CapsService.jsx',
          fn: 'updatePrice()-02',
        });
        Sentry.captureException(error);
      });
      return { error: error.message };
    });
}
/**
 * @typedef {{
 *  capAmount: string,
 *  capPeriod: string,
 *  id: string,
 *  paced: 0 | 1,
 * }[]} Caps
 * @param {string | number} idCampaign
 * @param {Caps} caps
 * @returns {Promise<{error: string} | {status: string}>}
 */
function updateCaps(idCampaign, caps) {
  const apiCaps = caps.map((cap) => ({
    cap_amount: cap.capAmount,
    cap_period: cap.capPeriod,
    id: cap.id,
    paced: cap.paced,
  }));
  const data = {
    caps: apiCaps,
    product_id: idCampaign,
  };
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return customFetch
    .post(`/change_caps.pl`, JSON.stringify(data), requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to POST change_caps.pl',
            file: 'CapsService.jsx',
            fn: 'updateCaps()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to POST change_caps.pl',
          file: 'CapsService.jsx',
          fn: 'updateCaps()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

function updatePublisherCaps(idPublisher, caps) {
  const data = {
    caps,
    id: idPublisher,
  };
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return customFetch
    .post(`/change_publisher_caps.pl`, JSON.stringify(data), requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: `Error trying to Post to change_caps.pl ${error.message}`,
            file: 'CapsService.jsx',
            fn: 'updatePublisherCaps()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: `Error trying to Post to change_caps.pl ${error.message}`,
          file: 'CapsService.jsx',
          fn: 'updatePublisherCaps()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

const updatePublisherCPC = async (campaignId, publisherId, percentage, clientId) => {
  const data = {
    client_id: clientId,
    publisher_id: publisherId,
    adjustment: percentage,
  };
  if (campaignId) {
    data.product_id = campaignId;
  }
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const result = await customFetch.post(`/client_bid.pl`, JSON.stringify(data), requestOptions);
  return result.data;
};

const updateCPCByDevice = async (campaignId, percentage, clientId, deviceType) => {
  const data = {
    adjustment: percentage,
    client_id: clientId,
    device_type: deviceType,
    device: 1,
  };

  if (campaignId) {
    data.product_id = campaignId;
  }
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return customFetch
    .post(`/client_bid.pl`, JSON.stringify(data), requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to POST client_bid.pl',
            file: 'CapsService.jsx',
            fn: 'updateCPCByDevice()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: `Error trying to POST client_bid.pl`,
          file: 'CapsService.jsx',
          fn: 'updateCPCByDevice()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
};

export const CapsService = {
  blockPublisher,
  getCampaigns,
  getCapPublishers,
  getFilterCampaigns,
  getValidJSTrackers,
  getDeviceBiddingStats,
  cloneCampaign,
  saveCampaign,
  updateCapStatus,
  updatePublisherStatus,
  updateTargeting,
  updatePrice,
  updateCaps,
  updatePublisherCaps,
  getRegions,
  getProducts,
  updatePublisherCPC,
  updateCPCByDevice,
  updateVariantStatus,
};
