import { useQuery } from '@tanstack/react-query';
import { customFetch } from '~/utils/customFetch';
import { ErrorObjectString, Binary } from '~/utils/typescriptHelpers';

export type OsEntry = {
  osName: string;
  osVersion: string;
  releaseYear: number;
  versionName: string;
  isComputer: Binary;
  isTablet: Binary;
  isMobile: Binary;
};

type RawOsTargetData = {
  [osName: string]: OsEntry[];
};

type ResponseData = RawOsTargetData | ErrorObjectString;

function isErrorObjectString(data: ResponseData): data is ErrorObjectString {
  return (data as ErrorObjectString).error !== undefined;
}

async function getOsTargetData() {
  const requestOptions = {
    headers: {
      'content-header': 'application/JSON',
    },
    useNodeEndpoint: true,
  };

  const { data: osData } = await customFetch.get<ResponseData>(
    '/authenticated/os-targets',
    requestOptions,
  );

  if (isErrorObjectString(osData)) {
    throw new Error(osData.error);
  }

  return osData;
}

export type OsTargets = Awaited<ReturnType<typeof getOsTargetData>>;

export function getOsTargetsQueryOptions() {
  return {
    queryKey: ['useOsTargetsQuery'],
    queryFn: async () => getOsTargetData(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
  };
}

export const useOsTargetsQuery = () => {
  return useQuery(getOsTargetsQueryOptions());
};

export default useOsTargetsQuery;
