import { useQuery } from '@tanstack/react-query';
import { customFetch } from '~/utils/customFetch';
import { ErrorObjectString } from '~/utils/typescriptHelpers';

type Audience = {
  id: string;
  label: string;
};

type ResponseData = Audience[] | ErrorObjectString;

async function getAudiences() {
  const { data } = await customFetch.get<ResponseData>(`/audiencesJSON.pl`);
  if ('error' in data) {
    throw new Error(data.error);
  }
  return data.map((audience) => ({
    id: Number(audience.id),
    label: audience.label,
  }));
}

export function getAudiencesQueryOptions() {
  return {
    queryKey: ['audiences'],
    queryFn: async () => getAudiences(),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
  };
}

export const useAudienceData = () => {
  return useQuery(getAudiencesQueryOptions());
};

export default useAudienceData;
