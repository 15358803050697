/* eslint-disable react/jsx-no-bind */
import * as React from 'react';
import * as Sentry from '@sentry/react';
import sidebarRoutes from '~/routes';

import { Outlet, useParams } from 'react-router-dom';

// Custom components
import AdminNavbar from '~/components/Commons/Navbars/AdminNavbar';
import Sidebar from '~/components/Commons/Sidebar/Sidebar';
import ErrorBoundaryPage from '~/views/Pages/ErrorBoundaryPage';
import DianomiSpinner from '~/components/Commons/DianomiSpinner/DianomiSpinner';

import { Box } from '@mui/material';

import adminStyle from '~/layouts/Admin/adminStyle';
// Context
// import useNotifications from '~/context/Notifications';
import useMiniMenu from '~/context/useMiniMenu';

// Services
import useWindowSize from '~/hooks/useWindowSize';

import { getActiveRoute, setRoutesPermissions } from './RoutePermissions';
import useSessionPlQuery from '../../hooks/queries/useSessionPlQuery';
import useGenieNavQuery from '../../hooks/queries/useGenieNavQuery';

export default function Admin() {
  const { accountId, accountType } = useParams();
  const { isOpen, toggle } = useMiniMenu();
  const { isMobile } = useWindowSize();
  const { data: sessionData, isLoading } = useSessionPlQuery();
  const { data: genieNavPlData } = useGenieNavQuery();
  const processedRoutes = React.useMemo(() => {
    if (!sessionData) return [];
    return setRoutesPermissions(sidebarRoutes, {
      ...sessionData,
      genieNav: genieNavPlData,
      emptyAccounts: false,
    });
  }, [genieNavPlData, sessionData]);
  return (
    <Box sx={adminStyle.root}>
      <Box sx={{ gridArea: 'header', position: 'sticky', top: 0, zIndex: 3000 }}>
        {!isLoading && sessionData?.id.toString() === accountId ? (
          <AdminNavbar
            sidebarMinimize={toggle}
            handleDrawerToggle={toggle}
            activeRoute={getActiveRoute(processedRoutes)}
            mobileNavOpen={isOpen && isMobile}
            showAccountSwitcher={!!accountId}
          />
        ) : (
          <AdminNavbar isAuthBar />
        )}
      </Box>
      {processedRoutes.length > 0 && accountId && sessionData?.id.toString() === accountId && (
        <Box sx={{ gridArea: 'sidebar' }}>
          <Sidebar
            key={sessionData?.id}
            routes={sessionData ? processedRoutes : []}
            basePath={`/${accountType}/${accountId}`}
          />
        </Box>
      )}
      <Box sx={[adminStyle.mainPanel, { gridArea: 'main', height: '100%' }]}>
        <DianomiSpinner loading={isLoading}>
          <Sentry.ErrorBoundary
            // eslint-disable-next-line react/jsx-props-no-spreading
            fallback={(props) => <ErrorBoundaryPage {...props} />}
          >
            <React.Suspense fallback={<DianomiSpinner loading delay={500} />}>
              <Outlet />
            </React.Suspense>
          </Sentry.ErrorBoundary>
        </DianomiSpinner>
      </Box>
    </Box>
  );
}
