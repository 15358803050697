import utils from '~/layouts/Admin/Admin.utils';
import { useQuery } from '@tanstack/react-query';
import useNotifications from '~/context/Notifications';
import useSessionPlQuery from '../useSessionPlQuery';

export type GenieNavPlData = {
  [title: string]: {
    stat_id: number;
    shortcode: string;
    takes_product: 0 | 1;
    takes_multiple_products: 0 | 1;
    name: string;
    linked: number;
    hidden_fields: number;
    visible_to_client: number;
    title: string;
    visible_to_partner: number;
    links: {
      [key: string]: number;
    };
  };
} | null;

export default function useGenieNavQuery() {
  const { data: sessionData } = useSessionPlQuery();
  const { setTimedNotification } = useNotifications();

  return useQuery({
    queryKey: ['genienav.pl', sessionData?.id, sessionData?.isPartner, sessionData?.nav?.stats],

    queryFn: () => {
      if (sessionData?.id) {
        // not really type-safe, but better than nothing
        return utils
          .handleGetGenieNav({
            id: sessionData.id,
            isPartner: sessionData.isPartner,
            stats: sessionData.nav.stats,
          })
          .catch(() => {
            setTimedNotification(
              'danger',
              'There has been an issue loading analytics data. If the issue persists, contact support at mydianomi@dianomi.com.',
              5000,
            );
            return null;
          }) as Promise<GenieNavPlData>;
      }

      return null;
    },
    enabled: !!sessionData,
    refetchOnWindowFocus: false,
  });
}
