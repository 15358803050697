import { useQuery } from '@tanstack/react-query';
import { customFetch } from '~/utils/customFetch';
import { ErrorObjectString } from '~/utils/typescriptHelpers';
import { capitalize } from '@mui/material';
import { Audience, Cohort } from '../useAdmanagerData/types';
import { useAccountParams } from '../../useAccountParams';

export type CohortType = {
  ownerClientId: number | null;
  cohorts: Array<Cohort>;
  audiences: Array<Audience>;
  ownerLogo: string;
  ownerTitleText: string;
  id: number;
  name: string;
};

type ResponseData = Array<CohortType> | ErrorObjectString;

async function getCohorts({ accountId }: { accountId?: number | string }) {
  const { data } = await customFetch.get<ResponseData>(
    `/authenticated/client/${accountId}/cohorts`,
    {
      headers: {
        'content-type': 'application/json',
      },
      useNodeEndpoint: true,
    },
  );
  if ('error' in data) {
    throw new Error(data.error);
  }

  const processedData = data.map((cohortType) => ({
    ...cohortType,
    name: capitalize(cohortType.name),
    ownerTitleText: capitalize(cohortType.ownerTitleText),
    audiences: cohortType.audiences.map((audience) => {
      return {
        ...audience,
        name: audience.name.replace(/\s?\[.*?\]$/, ''),
      };
    }),
  }));

  return processedData;
}

export function getCohortsQueryOptions(accountId?: number | string) {
  return {
    queryKey: ['cohorts', String(accountId)],
    queryFn: async () => getCohorts({ accountId }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
  };
}

export const useCohortQuery = () => {
  const accountParams = useAccountParams();
  return useQuery(getCohortsQueryOptions(accountParams?.accountId));
};

export default useCohortQuery;
