import HTMLReactParser from 'html-react-parser';
import * as Sentry from '@sentry/react';
import { customFetch } from '~/utils/customFetch';

const accountNameCompare = (a, b) => {
  const aUpper = a.name.toUpperCase();
  const bUpper = b.name.toUpperCase();
  return (aUpper > bUpper) - (bUpper > aUpper);
};

const getParents = (clientsData, isPartner = false) => {
  const clients = {};
  const flatClients = [];
  const updatedClientsData = { ...clientsData };
  const partnerOrClientParentId = isPartner ? 'parent_partner_id' : 'parent_client_id';
  for (const client in updatedClientsData) {
    updatedClientsData[client].isPartner = isPartner;

    flatClients.push(updatedClientsData[client]);

    if (!updatedClientsData[client][partnerOrClientParentId]) {
      // Is parent
      clients[client] = updatedClientsData[client];
      clients[client].children = [];
      delete updatedClientsData[client];
    }
  }
  return [clients, updatedClientsData, flatClients];
};

const getChildren = (oldClients, clientsData, isPartner) => {
  const clients = { ...oldClients };
  const partnerOrClientParentId = isPartner ? 'parent_partner_id' : 'parent_client_id';

  for (const client in clientsData) {
    if (clientsData[client][partnerOrClientParentId]) {
      // Is a child
      if (!clients[clientsData[client][partnerOrClientParentId]]) {
        // Orphan child (parent is archived)
        // We are handling it as parent with no children
        clients[client] = clientsData[client];
        clients[client].children = [];
      } else {
        // Child with legitime parent
        clients[clientsData[client][partnerOrClientParentId]].children.push(clientsData[client]);
        // Order
        clients[clientsData[client][partnerOrClientParentId]].children.sort(accountNameCompare);
      }
    }
  }
  return clients;
};

function getAccounts() {
  return customFetch
    .get(`/accounts.pl`)
    .then(
      (result) => {
        const clientsData = result.data.clients ? result.data.clients : {};
        const partnersData = result.data.partners ? result.data.partners : {};

        const [clients, newClientsData, flatClients] = getParents(clientsData, false);
        const clientsWithChildrenAttached = getChildren(clients, newClientsData, false);
        const [partners, newPartnersData, flatPartners] = getParents(partnersData, true);
        const partnersWithChildrenAttached = getChildren(partners, newPartnersData, true);
        const flatAccounts = [...flatClients, ...flatPartners];

        const clientsArray = Object.values(clientsWithChildrenAttached);
        const partnersArray = Object.values(partnersWithChildrenAttached);

        // Sort clients alphabetically
        clientsArray.sort(accountNameCompare);
        partnersArray.sort(accountNameCompare);
        // Get count of accounts
        const clientsCount = flatClients.length;
        const partnersCount = flatPartners.length;
        return {
          flatAccounts,
          clients: clientsArray,
          partners: partnersArray,
          clientsCount,
          partnersCount,
        };
      },
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET accounts.pl',
            file: 'ClientsService.jsx',
            fn: 'getAccounts()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET accounts.pl',
          file: 'ClientsService.jsx',
          fn: 'getAccounts()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

function getClientInfo(id = null) {
  let requestOptions = {};
  if (id) {
    requestOptions = {
      params: {
        id,
      },
    };
  }

  return customFetch
    .get(`/client.pl`, requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET client.pl',
            file: 'ClientsService.jsx',
            fn: 'getClientInfo()-01',
          });
          Sentry.captureException(error);
        });
        return {
          error: 'callback error',
        };
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET accounts.pl',
          file: 'ClientsService.jsx',
          fn: 'getClientInfo()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

function getProfileInfo() {
  const requestOptions = {};

  return customFetch
    .get(`/profile.pl`, requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET profile.pl',
            file: 'ClientsService.jsx',
            fn: 'getProfileInfo()-01',
          });
          Sentry.captureException(error);
        });
        return {
          error: 'callback error',
        };
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET profile.pl',
          file: 'ClientsService.jsx',
          fn: 'getProfileInfo()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

/**
 * @param {Object} [params={}] Object containing update parameters.
 * @param {string} [params.firstname] Optional first name.
 * @param {string} [params.lastname] Optional last name.
 * @param {boolean} [params.savedAds] Optional saved ads.
 * @param {Object[]} [params.clientMails] Optional array of objects for client emails, each containing an object with boolean flags.
 * @param {number} [params.phone] Optional phone number.
 * @param {{id: string, type: string}} [params.defaultAccountInfo] Optional default account information.
 * @returns {Promise<{success?: number, error?: string}>} A promise that resolves to an object indicating the success status or an error message.
 */
function updateProfileInfo({
  firstname,
  lastname,
  savedAds,
  clientMails,
  phone,
  defaultAccountInfo,
}) {
  const data = {
    firstname,
    lastname,
    saved_ads_reminder_email: savedAds,
    client_mails: clientMails,
    phone,
    default_account: defaultAccountInfo,
  };
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return customFetch
    .post(`/profile.pl`, JSON.stringify(data), requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to POST to profile.pl',
            file: 'ClientsService.jsx',
            fn: 'updateProfileInfo()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to POST to profile.pl',
          file: 'ClientsService.jsx',
          fn: 'updateProfileInfo()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

async function setClientSession(clientId, isPartner = false) {
  const requestOptions = {
    params: isPartner
      ? {
          partnerId: clientId,
        }
      : {
          clientId,
        },
  };

  const result = await customFetch.get(`/session.pl`, requestOptions).catch((error) => {
    Sentry.withScope((scope) => {
      scope.setTags({
        message: 'Error trying to GET session.pl',
        file: 'ClientsService.jsx',
        fn: 'setClientSession()-02',
      });
      Sentry.captureException(error);
    });
    throw new Error('Error trying to GET session.pl');
  });
  if (result?.data?.error === 'no session') {
    throw new Error('no session');
  }
  return result.data;
}

function getGenieNav() {
  return customFetch
    .get(`/genienav.pl`, { shouldUseBasePath: true })
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET genienav.pl',
            file: 'ClientsService.jsx',
            fn: 'getGenieNav()-01',
          });
          Sentry.captureException(error);
        });
        // Returning null instead of throwing to prevent hitting the error boundary when it may not be necesarry
        // E.g. failing to load analytics routes even when the user may not actually be trying to use them
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET genienav.pl',
          file: 'ClientsService.jsx',
          fn: 'getGenieNav()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

function getClientSessions() {
  return customFetch
    .get(`/user_sessions.pl`)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET user_sessions.pl',
            file: 'ClientsService.jsx',
            fn: 'getClientSessions()-01',
          });
          Sentry.captureException(error);
        });
        return false;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET user_sessions.pl',
          file: 'ClientsService.jsx',
          fn: 'getClientSessions()-02',
        });
        Sentry.captureException(error);
      });
      return false;
    });
}

function closeClientSessions() {
  return customFetch
    .get(`/logout_all.pl`)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET logout_all.pl',
            file: 'ClientsService.jsx',
            fn: 'closeClientSessions()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET logout_all.pl',
          file: 'ClientsService.jsx',
          fn: 'closeClientSessions()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

async function getPublisherPaymentHistory(clientId) {
  const requestOptions = {
    partnerId: clientId,
  };

  const result = await customFetch.get(`/payment_history.pl`, requestOptions);
  if (result.status === 200 && result.data.error) {
    Sentry.withScope((scope) => {
      scope.setTags({
        message: 'Error trying to GET payment_history.pl',
        file: 'ClientsService.jsx',
        fn: 'getPublisherPaymentHistory()-02',
      });
      Sentry.captureException(new Error('Error trying to GET payment_history.pl'));
    });
    throw new Error('Error trying to GET payment_history.pl');
  } else if (result.data) {
    return result.data.sort((a, b) => (parseInt(a.yyyymm, 10) > parseInt(b.yyyymm, 10) ? -1 : 1));
  }
  throw new Error('Unknown Error');
}

function getPublisherAds() {
  return customFetch
    .get(`${window.location.origin}/ads_1_1.html`)
    .then(
      (result) => {
        const data = {
          rows: [],
          title: '',
        };
        // Parse HTML to extract table data
        const html = document.createRange().createContextualFragment(result.data);
        data.title = html.querySelector('#content h1').innerText;
        const htmlTable = html.querySelector('table.table tbody');
        if (htmlTable) {
          const rows = [...htmlTable.childNodes];
          data.rows = rows
            .filter((item) => item.cells)
            .map((column) =>
              [...column.cells].map((content) => HTMLReactParser(content.innerHTML)),
            );
        }
        return data;
      },
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET ads_1_1.pl',
            file: 'ClientsService.jsx',
            fn: 'getPublisherAds()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET ads_1_1.pl',
          file: 'ClientsService.jsx',
          fn: 'getPublisherAds()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

function saveClientUsers(userData) {
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return customFetch
    .post(`/client_logins.pl`, JSON.stringify(userData), requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET client_logins.pl',
            file: 'ClientsService.jsx',
            fn: 'getPublisherAds()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET client_logins.pl',
          file: 'ClientsService.jsx',
          fn: 'getPublisherAds()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

function updateClientInfo(clientName) {
  const data = {
    name: clientName,
  };
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return customFetch
    .post(`/client.pl`, JSON.stringify(data), requestOptions)
    .then(
      (result) => result.data,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to GET client.pl',
            file: 'ClientsService.jsx',
            fn: 'updateClientInfo()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to GET client.pl',
          file: 'ClientsService.jsx',
          fn: 'updateClientInfo()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

/**
 *
 * @param {string} logo
 * @returns string
 */
async function uploadLogo(logo) {
  if (logo === '') {
    return Promise.resolve('');
  }
  const data = new FormData();
  data.append('file', logo);
  try {
    const res = await customFetch.post(`/jquery_upload2.pl`, data);
    let logoPath = '';
    if (res.data && res.data.files && res.data.files[0]) {
      logoPath = res.data.files[0].url;
    }
    return logoPath;
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setTags({
        message: 'Error trying to POST jquery_upload2.pl',
        file: 'ClientsService.jsx',
        fn: 'uploadLogo()-02',
      });
      Sentry.captureException(error);
    });
    return '';
  }
}

function addNewClient(clientName, parent, logoPath) {
  const data = {
    name: clientName,
    parent_client_id: parent,
    logo_400x320: logoPath,
  };
  const requestOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return customFetch
    .post(`/client.pl`, JSON.stringify(data), requestOptions)
    .then(
      (result) => result,
      (error) => {
        Sentry.withScope((scope) => {
          scope.setTags({
            message: 'Error trying to POST client.pl',
            file: 'ClientsService.jsx',
            fn: 'addNewClient()-01',
          });
          Sentry.captureException(error);
        });
        return null;
      },
    )
    .catch((error) => {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'Error trying to POST client.pl',
          file: 'ClientsService.jsx',
          fn: 'addNewClient()-02',
        });
        Sentry.captureException(error);
      });
      return null;
    });
}

export const ClientsService = {
  getAccounts,
  getClientInfo,
  getClientSessions,
  closeClientSessions,
  getPublisherPaymentHistory,
  getPublisherAds,
  saveClientUsers,
  setClientSession,
  getProfileInfo,
  updateProfileInfo,
  updateClientInfo,
  addNewClient,
  uploadLogo,
  getGenieNav,
};
